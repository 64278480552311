import React, { FC } from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const osio: FC = () => {
  const titleImage = "_DSC2456.jpg"
  const image1 = "_DSC2420.jpg"
  const image2 = "_DSC2423.jpg"
  const image3 = "_DSC2440.jpg"
  const image4 = "_DSC2448.jpg"

  return (
    <Layout>
      <div className="single-project">
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Osio/" + titleImage}
            alt="Ghidoni"
          />
        </div>
        <h3 className="single-project-picture-landscape">
          Osio 2021
          <h5 className="single-project-text">
            In zona edificabile a Mergoscia su via Fesnèd il signor Osio
            possiede da tanto tempo un’abitazione secondaria e mi ha espresso la
            volontà di costruire un’atelier per la sua compagna che è
            un’artista. Il desiderio era quello di fare una costruzione
            massiccia in legno di larice che secondo lui a livello percettivo è
            molto meglio di una costruzione tradizionale. Sfruttando la
            possibilità di ampliamento del 30% della superficie abitabile
            concessa dalla legge sulle residenze secondarie e rispettando tutti
            gli arretramenti dal ruscello e dal bosco, abbiamo quindi inserito
            la nuova costruzione nel suo mappale. Si sono scavati circa 20 metri
            cubi di roccia dalla quale sono stati recuperate tutte le pietre
            necessarie per fare il terrazzamento di sostegno. Non essendo
            l’atelier un ambiente riscaldato le travi di larice dallo spessore
            di 10cm sono state lasciate a vista sia internamente che
            esternamente. Le superfici esterne non sono state trattate in modo
            da permettere il naturale ingrigimento dovuto al sole e alla
            pioggia. Ad est è stato prolungato il tetto in modo da creare uno
            spazio coperto dove poter dipingere, per sorreggere le travi ho
            fatto personalmente dei pilastri in castagno.
          </h5>
        </h3>
        <div>
          <StaticImage
            src={"../../assets/images/Osio/" + image1}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Osio/" + image2}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Osio/" + image3}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Osio/" + image4}
            alt="Alambicco"
          />
        </div>
      </div>
    </Layout>
  )
}

export default osio
